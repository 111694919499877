body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*REACT DROPZONE*/
.dropzone { transition: all 0.3s; height: 15rem; text-align: center; cursor: pointer; border: 2px #dbdbdb dashed; } 
  .dropzone:hover { border-color: #3273dc; }
.dropzone-label { transition: all 0.3s; padding: 5em 1em 0 1em; font-size: 1.1em; }
  .dropzone:hover .dropzone-label { color: #3273dc; } 

.dropzone-label .main-content { padding: 6rem; } 
