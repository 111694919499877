#root {
  background-color: #f1f1f1; }

.singleCompany {
  background: #fff;
  padding: 25px;
  border-radius: 6px; }

.menu-list ul .navbar-item::before {
  display: none; }

/* Navbar */
.react-tabs {
  width: calc(100% - 250px);
  margin-left: 250px;
  padding: 30px 20px 20px 20px; }

.react-tabs__tab-panel .content {
  margin-left: 0px;
  background: white;
  width: 100%;
  border-radius: 6px; }

.content {
  margin-left: 250px;
  width: calc(100% - 250px);
  padding: 30px 20px 20px 20px; }

.settings-block .field-label {
  width: 100%;
  display: block; }

.settings-block .event-docs {
  display: block;
  width: 100%;
  text-align: left; }
  .settings-block .event-docs a {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px; }
  .settings-block .event-docs .gridIcon {
    display: inline-block; }
    .settings-block .event-docs .gridIcon img {
      width: 20px;
      display: inline-block; }

.settings-block {
  display: block;
  width: 100%;
  margin-top: 20px; }
  .settings-block .field-label {
    display: block;
    width: 100%;
    margin-bottom: 10px; }
    .settings-block .field-label .bcwLabel {
      margin-top: 0 !important; }

.facturenRow.eventSponsors {
  background: #f8f8f8 !important; }

.eventSponsors {
  width: 100%; }
  .eventSponsors .eventSponsorRow {
    width: 100%;
    background: #f1f1f1;
    padding: 20px;
    margin-bottom: 20px; }
    .eventSponsors .eventSponsorRow .button {
      margin-left: 15px; }

.payTerms .facturenRow {
  background: #f1f1f1;
  padding: 20px;
  margin-bottom: 20px; }
  .payTerms .facturenRow .button {
    margin-left: 15px; }

.upload_file_tab .uploaded-files {
  margin: 40px 0 !important; }
  .upload_file_tab .uploaded-files .bcwLabel {
    margin-bottom: -20px !important; }

.modal .facturenRowWrapper .facturenRow {
  background: #f1f1f1;
  padding: 20px;
  margin-bottom: 20px;
  max-width: 100%;
  border-radius: 6px; }

.modal .content {
  margin-left: 0px;
  width: 100%; }

.modal .field {
  margin-bottom: 20px !important; }
  .modal .field .field {
    margin-bottom: 0 !important; }

.modal .facturenRowWrapper {
  width: 100%;
  display: block; }

.contentPanel .field {
  margin-bottom: 20px !important; }
  .contentPanel .field .field {
    margin-bottom: 0 !important; }

.actieProductenRowWrapper {
  width: 100%;
  display: block; }
  .actieProductenRowWrapper .button.is-success {
    margin-left: 10px; }
  .actieProductenRowWrapper .actieProductenRow {
    margin: 10px 0; }
    .actieProductenRowWrapper .actieProductenRow .field {
      display: inline-block !important; }
      .actieProductenRowWrapper .actieProductenRow .field .field-body {
        width: 100% !important; }
      .actieProductenRowWrapper .actieProductenRow .field .field {
        width: 100%; }
    .actieProductenRowWrapper .actieProductenRow .delete-btn {
      display: inline-block !important; }

.sidebar-menu {
  width: 250px;
  position: fixed;
  height: 100%;
  z-index: 99999;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1); }
  .sidebar-menu .intro-name {
    padding: 0px 5px 30px 25px;
    display: block; }
  .sidebar-menu .logo {
    width: 100%;
    height: auto !important;
    text-align: center;
    display: block;
    margin: 20px 0px; }
    .sidebar-menu .logo img {
      width: 140px;
      height: 140px;
      margin: 0 auto; }
  .sidebar-menu .menu {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 250px); }
  .sidebar-menu .normal-link:before {
    display: none; }
  .sidebar-menu .menu-list .menu-list .navbar-item:before {
    display: none; }
  .sidebar-menu .menu-list a {
    color: #4a4a4a;
    border-bottom: 1px solid #eee;
    padding: 0.7em 1.6em;
    transition: all 350ms ease; }
  .sidebar-menu .menu-list a.is-active {
    color: #fff;
    background-color: #02b0aa !important; }
  .sidebar-menu .menu-list a:hover {
    background-color: rgba(2, 176, 170, 0.5);
    color: #fff; }
  .sidebar-menu .menu-list a::before {
    content: "\f105";
    font-family: fontawesome;
    color: #666;
    margin-right: 10px; }
  .sidebar-menu .menu-list ul {
    padding-left: 20px; }
    .sidebar-menu .menu-list ul a {
      color: #666;
      font-size: 0.9em; }

.navbar {
  padding: 0px 30px;
  border: none;
  margin-bottom: 20px !important;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1); }
  .navbar .navbar-brand .navbar-item {
    padding: 0;
    position: absolute; }
    .navbar .navbar-brand .navbar-item img {
      max-height: 5.75rem; }

@media only screen and (max-width: 1085px) {
  .navbar-brand {
    display: inline-block !important;
    min-height: 0px;
    margin-top: -20px; }
  .navbar-start {
    display: inline-block !important;
    width: auto; }
    .navbar-start .navbar-item {
      display: inline-block !important; }
  .navbar-end {
    display: inline-block !important;
    margin-left: 0 !important;
    min-height: 0px; } }

@media only screen and (max-width: 1085px) {
  #navbar.navbar-menu {
    display: flex !important;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    align-items: stretch;
    box-shadow: none; } }

#navbar .navbar-start {
  justify-content: flex-start;
  margin-right: auto;
  display: flex; }

#navbar .navbar-end {
  justify-content: flex-end;
  margin-left: auto;
  display: flex; }
  #navbar .navbar-end img {
    vertical-align: middle; }

.logout-btn {
  transition: all 350ms ease;
  position: relative;
  padding: 15px; }
  .logout-btn img {
    width: 25px;
    display: inline-block;
    margin-right: 10px;
    transition: all 350ms ease; }
  .logout-btn:hover img {
    filter: brightness(0%); }

#navbar {
  margin-left: 120px; }
  #navbar .navbar-item {
    color: #4e5983;
    font-size: 1rem;
    font-weight: 500;
    transition: all 350ms ease;
    padding-left: 30px;
    padding-right: 30px; }
    #navbar .navbar-item:hover {
      background: rgba(78, 89, 131, 0.3);
      color: #fff; }

.floating-btn-bar {
  position: fixed;
  z-index: 3;
  background: #fff;
  bottom: 0;
  width: calc(100% + 40px);
  padding: 20px 20px 30px 20px;
  border-radius: 0px;
  margin: 0 auto;
  right: 0px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1); }
  @media only screen and (max-width: 770px) {
    .floating-btn-bar {
      max-width: 100%; } }

.radio-wrapper label {
  margin-right: 15px; }
  .radio-wrapper label input {
    margin-right: 5px; }

.wysiwyg-edit {
  padding-left: 10px;
  margin-bottom: 20px; }
  .wysiwyg-edit .label {
    font-size: 0.8rem !important;
    text-align: left !important; }

.actieProductenRow .field-body {
  width: 300px; }

.rdw-editor-main {
  padding: 0 15px;
  border: 1px solid #f1f1f1;
  margin-top: -6px;
  min-height: 250px; }

.facturenRow.eventSponsors {
  background: #fff;
  border-radius: 6px;
  width: 90%;
  margin-left: 15px; }

.footer {
  padding: 0 20px 20px 0px;
  text-align: right;
  margin-top: 20px;
  background: #f1f1f1 !important; }
  .footer img {
    width: 200px;
    display: inline-block;
    vertical-align: middle;
    opacity: 0.5;
    transition: all 350ms ease; }
    .footer img:hover {
      opacity: 1; }
  .footer span {
    display: inline-block;
    vertical-align: middle;
    font-style: italic; }

/* Login */
#login {
  min-height: 100vh;
  height: auto;
  background-image: url("./images/background_login.jpg");
  background-size: cover;
  margin: 0; }
  #login .field {
    margin-bottom: 15px !important; }
  #login .forgot-password {
    margin-top: 20px;
    float: right;
    margin-top: 10px;
    border-bottom: 1px solid #00b0a6;
    padding-bottom: 4px; }
    #login .forgot-password img {
      width: 15px;
      display: inline-block;
      vertical-align: middle; }
    #login .forgot-password span {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      color: #00b0a6;
      font-size: 14px; }

#panel {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40%;
  left: 0;
  height: 100%;
  border-radius: 0px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%; }
  #panel img {
    width: 160px;
    height: auto; }
  #panel form {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    left: 0;
    right: 0;
    margin: 0 auto; }
    #panel form .field {
      margin: 15px 0 0px 0 !important; }
    #panel form .button {
      margin-top: 15px; }
  @media only screen and (max-width: 1050px) {
    #panel {
      width: 70%; } }
  @media only screen and (max-width: 650px) {
    #panel {
      width: 90%;
      display: block;
      height: 420px;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-radius: 10px; }
      #panel form {
        width: 100%;
        position: relative;
        transform: translateY(0);
        top: 0; } }

.navbar-item.has-dropdown .navbar-link {
  background: none !important;
  transition: all 350ms ease; }
  .navbar-item.has-dropdown .navbar-link:hover {
    color: #fff; }

.navbar-item.has-dropdown:hover .navbar-link {
  color: #fff; }

.field .field-label .label {
  font-size: 0.8rem !important;
  text-align: left !important; }

.field-body {
  position: relative; }
  .field-body .help.is-danger {
    display: block;
    top: -30px;
    right: 0;
    position: absolute; }

#header h1 {
  font-size: 2rem;
  font-weight: 600; }

#header img {
  float: right;
  height: 50px; }

.loginButton,
.button {
  background-color: #00b0a6 !important;
  font-size: 0.9rem;
  text-transform: none;
  border-radius: 45px;
  font-weight: 600;
  padding: 5px 25px;
  transition: all 350ms ease; }
  .loginButton:hover,
  .button:hover {
    opacity: 0.8; }

#login .is-danger {
  background-color: #ff6f79 !important;
  color: #fff;
  border: none;
  margin-right: 10px; }

input:disabled {
  pointer-events: none;
  opacity: 0.6;
  cursor: default !important; }

.singleCompany .bcwLabel {
  float: none !important;
  margin-bottom: 10px !important;
  text-align: left;
  font-size: 12.8px; }

.singleCompany .columns > .field.column > .field.column {
  padding: 0 !important; }

.season.column div > .field.column {
  padding-left: 0 !important; }

/* Button */
.button {
  border-radius: 6px;
  padding: 0.6rem 1.4rem;
  box-sizing: border-box;
  white-space: nowrap;
  height: auto;
  font-size: 1rem; }
  .button:not(.is-success) {
    background: transparent !important;
    color: #2f4858;
    border: 2px solid #2f4858; }
  .button.delete-btn {
    padding: 0.3rem 1.4rem; }

.border-spacer {
  display: block;
  width: calc(100% - 20px);
  background: #9eadbd;
  height: 1px;
  margin: 30px auto;
  opacity: 0.6; }

.react-tabs__tab-list {
  border-bottom: none !important; }
  .react-tabs__tab-list .react-tabs__tab {
    color: #9eadbd !important; }
  .react-tabs__tab-list .react-tabs__tab--selected {
    border: none !important;
    border-bottom: 2px solid #00b0a6 !important;
    background: none;
    color: #00b0a6 !important;
    font-weight: 600; }

.deliverSpecs {
  display: block;
  width: 100%;
  margin-bottom: 25px; }
  .deliverSpecs span {
    background: #fff;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    display: block; }
    .deliverSpecs span p {
      font-weight: 400; }

.input:read-only {
  cursor: default;
  background-color: #f2f2f2; }

.file-upload-img {
  height: 100px;
  margin-top: 12px; }

.uploaded-files {
  text-align: center;
  margin-bottom: 30px !important; }
  .uploaded-files .dropzone-label {
    padding: 0px;
    position: relative; }
    .uploaded-files .dropzone-label > div span {
      position: absolute;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      left: 0;
      right: 0; }
  .uploaded-files .field-label {
    text-align: center; }
    .uploaded-files .field-label .label {
      text-align: center;
      margin: 0 auto;
      display: block; }

.dashboard-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; }
  .dashboard-content h3 {
    margin-top: 0px !important;
    margin-bottom: 30px !important; }
  .dashboard-content .dashboard-column-chart {
    width: 49%;
    margin-right: 1%;
    margin-bottom: 20px;
    height: 500px;
    max-height: 500px;
    background-color: #fff;
    border-radius: 6px; }
    .dashboard-content .dashboard-column-chart .chart-block {
      width: 100%;
      display: block; }
    .dashboard-content .dashboard-column-chart .dashboard-grid-view {
      width: 98%;
      display: block; }
      .dashboard-content .dashboard-column-chart .dashboard-grid-view .ReactTable {
        height: 100% !important;
        max-height: 100% !important; }
    @media only screen and (max-width: 1170px) {
      .dashboard-content .dashboard-column-chart {
        width: 100% !important;
        margin-right: 0%; } }
  .dashboard-content .dashboard-column-chart h3 {
    font-size: 18px !important; }
  .dashboard-content .dashboard-column {
    width: 49%;
    margin-right: 1%;
    margin-bottom: 20px;
    height: 600px;
    max-height: 600px;
    background-color: #fff;
    border-radius: 6px; }
    .dashboard-content .dashboard-column h3 {
      font-size: 18px !important; }
    .dashboard-content .dashboard-column .chart-block {
      width: 100%;
      display: block; }
    .dashboard-content .dashboard-column .dashboard-grid-view {
      width: 98%;
      display: block; }
      .dashboard-content .dashboard-column .dashboard-grid-view .ReactTable {
        height: 100% !important;
        max-height: 100% !important; }
    @media only screen and (max-width: 1170px) {
      .dashboard-content .dashboard-column {
        width: 100% !important;
        margin-right: 0%; } }

.field-custom-list .field-label {
  margin-left: 0 !important; }

#root .dashboard-content .ReactTable {
  height: 475px !important;
  max-height: 475px !important; }

.dashboard-grid-view {
  width: calc(50% - 20px);
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 20px;
  background: #fff;
  padding: 20px 20px;
  border-radius: 6px; }
  @media screen and (max-width: 768px) {
    .dashboard-grid-view {
      width: 100%;
      display: block;
      margin-right: 0px; } }

.dashboard-grid-view h3 {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 10px; }

.chart-block {
  background: #fff;
  display: block;
  width: 98%;
  padding: 20px;
  margin: 20px auto 10px auto;
  background: #fff;
  border: none;
  border-radius: 6px;
  overflow: hidden;
  float: left; }
  .chart-block h3 {
    font-weight: bold;
    margin-top: -20px;
    margin-bottom: 30px;
    margin-left: 5px; }
  .chart-block.chart-halve {
    width: calc(50% - 20px);
    display: inline-block; }
  .chart-block.chart-25 {
    width: calc(25% - 20px);
    display: inline-block; }
    .chart-block.chart-25:last-child {
      float: right; }
    @media screen and (max-width: 900px) {
      .chart-block.chart-25 {
        width: 100%;
        display: block; } }

.chart-wrapper {
  width: 98%;
  margin: 0 auto; }

#root {
  background-color: #f1f1f1;
  background-size: 100%; }
  #root .column {
    padding-top: 0;
    padding-bottom: 0; }
  #root .archive-button {
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 18px center; }
  #root .add-button {
    background-image: url("images/add-circle.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 18px center;
    padding-left: 45px; }
  #root .seasonDropdown {
    margin-left: -10px;
    margin-top: 0px;
    margin-bottom: -30px;
    float: left; }
    #root .seasonDropdown .buttons {
      width: 300px; }
  #root .ReactTable {
    background: #fff;
    border: none;
    border-radius: 6px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.055);
    height: 75vh !important; }
    #root .ReactTable .rt-resizable-header-content {
      padding: 10px 15px;
      color: #303752;
      font-weight: 600;
      font-size: 0.7rem;
      text-transform: none; }
    #root .ReactTable .gridIcon img {
      max-width: 25px; }
    #root .ReactTable .rt-th input {
      background-image: url("images/Search.svg");
      background-repeat: no-repeat;
      padding-left: 24px;
      background-size: 12px;
      background-position: 6px center;
      border: none;
      background-color: transparent; }
    #root .ReactTable rt-td {
      border: none; }
    #root .ReactTable .rt-tr-group {
      border: none; }
      #root .ReactTable .rt-tr-group .rt-tr {
        padding: 1px 10px;
        color: #4e5983;
        font-weight: 600;
        font-size: 0.8rem;
        cursor: pointer;
        transition: all 350ms ease; }
        #root .ReactTable .rt-tr-group .rt-tr.-odd {
          background-color: #f9fafc; }
        #root .ReactTable .rt-tr-group .rt-tr:hover {
          background: rgba(78, 89, 131, 0.2); }
  #root .-previous button {
    width: 200px; }
  #root .-next button {
    width: 200px;
    float: right; }

.pricing {
  display: table;
  width: auto;
  background-color: #f1f1f1;
  border-spacing: 5px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  margin-top: 20px;
  margin-left: auto; }
  .pricing .pricing-row {
    display: table-row;
    width: auto;
    clear: both; }
    .pricing .pricing-row .pricing-row-col {
      float: left;
      display: table-column;
      width: 200px;
      background-color: #f1f1f1; }

.payTerms .checkbox {
  margin-left: -10px;
  margin-top: 15px; }
  .payTerms .checkbox .label {
    display: inline-block;
    margin-right: 10px; }
  .payTerms .checkbox input {
    display: inline-block; }

/* */
.min-height-textarea {
  width: 50%; }
  .min-height-textarea .column.is-half-desktop {
    width: 100% !important; }
  .min-height-textarea textarea {
    min-height: 120px;
    width: 100% !important; }

.addPrestatie {
  display: block;
  width: 160px; }

.modal-card-body {
  overflow-x: hidden; }
  .modal-card-body .control input {
    margin-right: 5px; }
  .modal-card-body .floating-btn-bar {
    display: none !important; }
  .modal-card-body .content {
    padding: 0; }

.modal-card {
  width: 60vw !important;
  transform: translateX(-50%) !important;
  left: calc(50% - 150px) !important; }
  @media only screen and (max-width: 1140px) {
    .modal-card {
      left: calc(50% - 70px) !important; } }

.grey-block {
  background: #f9fafc;
  padding: 0px 0px 40px 0 !important;
  margin: 60px 0 60px 0 !important;
  display: block;
  width: 100%;
  position: relative; }
  .grey-block .field.column {
    display: inline-block !important;
    width: calc(50% - 10px); }
    @media only screen and (max-width: 768px) {
      .grey-block .field.column {
        width: 100%;
        display: block; } }
  .grey-block h3 {
    margin-top: 0 !important; }
  .grey-block:after {
    content: " ";
    left: -100%;
    top: 0;
    width: 100%;
    display: block;
    height: 100%;
    position: absolute;
    background: #f9fafc;
    z-index: 1; }
  .grey-block:before {
    content: " ";
    right: -100%;
    top: 0;
    width: 100%;
    display: block;
    height: 100%;
    position: absolute;
    background: #f9fafc;
    z-index: 2; }

.spacer-title {
  display: block;
  width: 100% !important;
  border-radius: 6px;
  position: relative;
  margin-left: 10px;
  margin-top: -20px !important;
  background-image: url("images/grass.jpg");
  color: #fff !important;
  padding: 30px 55px;
  background-size: cover;
  background-position: center;
  margin: 30px auto 30px auto !important; }

.marginless-spacer-title {
  display: block;
  width: 100% !important;
  border-radius: 6px;
  position: relative;
  margin-left: 10px;
  margin-top: -20px !important;
  background-image: url("images/grass.jpg");
  color: #fff !important;
  padding: 30px 55px;
  background-size: cover;
  background-position: center;
  margin: 30px auto 30px auto; }

.season {
  width: 100%;
  margin: 0 auto; }
  .season > div {
    width: 100%;
    margin-bottom: 40px;
    display: inline-block;
    position: relative; }
    .season > div .field-custom-list {
      margin-top: 0; }
    .season > div:first-child {
      margin-top: 20px; }
    .season > div:nth-child(even) {
      background: #f9fafc;
      padding: 30px 0;
      position: relative;
      z-index: 1; }
      .season > div:nth-child(even):before {
        content: " ";
        right: -1.8%;
        width: 1.8%;
        top: 0;
        bottom: 0;
        height: 100%;
        display: block;
        position: absolute;
        z-index: 1;
        background: #f9fafc; }
      .season > div:nth-child(even):after {
        content: " ";
        left: -1.8%;
        width: 1.8%;
        top: 0;
        bottom: 0;
        height: 100%;
        display: block;
        position: absolute;
        z-index: 1;
        background: #f9fafc; }

.checkbox-wrapper {
  width: 100% !important;
  display: block;
  flex: none;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  height: auto;
  padding: 18px 15px 5px 15px; }
  .checkbox-wrapper .checkbox {
    margin-bottom: 0 !important;
    padding: 15px; }
    .checkbox-wrapper .checkbox label {
      vertical-align: -webkit-baseline-middle !important; }

.checkboxNieuwsbericht .checkbox-wrapper {
  width: auto;
  background: none;
  box-shadow: none;
  border-radius: 0px;
  padding: 0;
  vertical-align: middle; }

.modal-card .addPrestatie {
  margin-left: 10px;
  margin-bottom: 40px; }

.field-custom-list {
  display: inline-block;
  width: 50%;
  position: relative; }
  .field-custom-list.field-custom-list-settings {
    margin-top: 0px; }
  .field-custom-list .column.is-half-desktop {
    width: calc(100% - 60px) !important; }
  .field-custom-list input {
    margin-bottom: 15px; }
  .field-custom-list .field-label {
    margin-left: 10px; }
  @media only screen and (max-width: 1070px) {
    .field-custom-list {
      width: 100%; } }

.firstRadio--2 input {
  margin-left: -80px; }

.facturenRow {
  width: 200%;
  margin-bottom: 40px; }
  .facturenRow > .field {
    width: 100%; }
    .facturenRow > .field .labelValue {
      visibility: middle;
      display: inline-block;
      margin-left: 10px; }

.custom-label {
  margin-top: 30px !important; }
  .custom-label .labelValue {
    margin-top: 6px;
    margin-left: 10px;
    color: green;
    display: inline-block; }

.dropzone {
  margin-top: 36px;
  height: 8rem; }

.dropzone-label {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  margin-top: 20px !important; }
  .dropzone-label > div {
    position: relative;
    height: 100%;
    width: 100%;
    margin-top: -20px; }
    .dropzone-label > div p {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      height: 100%;
      width: 100%;
      margin-top: 0px;
      margin: 0px auto 0 auto; }
      .dropzone-label > div p span {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center; }

.fold-icon {
  cursor: pointer;
  display: block;
  margin-left: 30px;
  margin-bottom: 15px;
  width: 25px;
  height: 35px;
  margin-top: 60px;
  position: absolute;
  left: -10px;
  z-index: 3; }
  .fold-icon img {
    width: 100%; }

.dropzone {
  display: block;
  width: 100%; }

.uploaded-files .field-label {
  display: block;
  width: 100%; }

.modal-card-body .custom-uploaded-files {
  display: block; }
  .modal-card-body .custom-uploaded-files .dropzone {
    display: block;
    width: 100%;
    margin-top: 0; }
  .modal-card-body .custom-uploaded-files .custom-docs {
    display: block;
    width: 100%;
    margin-top: 0; }
  .modal-card-body .custom-uploaded-files .field-label {
    display: block;
    width: 100%;
    margin-bottom: 10px; }

.modal-card-body .custom-docs .gridIcon {
  margin-left: 10px;
  vertical-align: middle;
  width: 20px;
  display: inline-block; }

.modal-card-body .notulenRow {
  background: #f8f8f8;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px; }
  .modal-card-body .notulenRow .field-body {
    width: 700px; }

.gridIconDelete {
  margin-left: 10px;
  vertical-align: middle;
  width: 20px;
  display: inline-block; }

.checkbox-wrapper .checkbox {
  display: block;
  margin-bottom: 20px; }
  .checkbox-wrapper .checkbox label {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px; }
  .checkbox-wrapper .checkbox input {
    display: inline-block;
    vertical-align: middle; }

.field {
  display: block !important; }
  .field .field-label {
    display: block !important;
    margin-bottom: 30px !important;
    flex: none !important;
    max-height: 0px !important; }
  .field .field-body {
    display: block !important; }

.seasonDropdown > .buttons > .field {
  display: flex !important; }
  .seasonDropdown > .buttons > .field .field-body {
    width: 280px; }

.field-custom-list-settings {
  width: 100%;
  display: block; }
  .field-custom-list-settings label {
    margin-bottom: 20px; }
  .field-custom-list-settings .field-label {
    margin-left: 10px !important; }
  .field-custom-list-settings .field.is-half-desktop {
    display: inline-block !important;
    width: calc(100% - 78px) !important;
    vertical-align: middle; }
  .field-custom-list-settings .delete-btn {
    display: inline-block;
    vertical-align: top;
    margin-left: 0; }

.delete-btn {
  color: transparent !important;
  width: 50px;
  background-image: url("images/bin.png");
  background-size: 40%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ff6f79 !important;
  vertical-align: middle;
  margin-left: 15px; }

.delete-button {
  background-image: url("images/bin.png");
  background-size: 20px;
  padding-left: 40px;
  background-position: 10px center;
  background-repeat: no-repeat;
  background-color: #ff6f79 !important;
  border-color: #ff6f79 !important; }

.mobile-error {
  display: none;
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  max-width: 80%;
  margin: 0 auto;
  transform: translateY(-50%);
  left: 0;
  right: 0; }
  .mobile-error img {
    margin: 20px auto;
    max-width: 30%; }

@media only screen and (max-width: 768px) {
  #root .main-content {
    display: none; }
  body {
    background-image: url("./images/background_waves.png");
    background-size: 100% auto;
    height: 100%; }
  .mobile-error {
    display: block; } }

/* React table overrides */
.ReactTable .rt-thead {
  overflow-y: scroll;
  scrollbar-width: none;
  /* IE properties (edit this according to your background color) */
  scrollbar-track-color: #fff;
  scrollbar-3dlight-color: #fff;
  scrollbar-darkshadow-color: #fff;
  scrollbar-arrow-color: #fff; }

.ReactTable .rt-tbody {
  scrollbar-width: none; }

.ReactTable .rt-thead::-webkit-scrollbar {
  background: transparent; }

.seasonInvoiced {
  height: 18px;
  width: 18px;
  background-color: #969696;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  color: #ffffff;
  margin: 2px;
  text-decoration: line-through; }

.seasonNotInvoiced {
  height: 18px;
  width: 18px;
  background-color: #d44242;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  color: #ffffff;
  margin: 2px; }

.imageCropper .crop-container {
  width: 100%;
  height: 300px;
  position: relative; }

.imageCropper .controls {
  margin: auto;
  width: 50%;
  display: flex;
  align-items: center; }
